import React from "react"
import { graphql } from "gatsby"
import Carousel from "react-bootstrap/Carousel"

import "../styles/wacky.scss"

import SEO from "../components/seo"
import { Container } from "react-bootstrap"
import BackgroundImage from "gatsby-background-image"
import "typeface-raleway"

const WackyPage = ({ data }) => (
  <Container fluid id="wacky">
    <SEO title="Retro Roaming" description="" keywords="" />
    <BackgroundImage
      id="welcome"
      tag="section"
      className="section"
      fluid={data.bonairsAndDogs.childImageSharp.fluid}
    >
      <h1 className="retro-shadow">Retro Roamers</h1>
      <h2>
        Stay with Retro Roaming at the glorious Southwold campsite in our
        fabulous retro themed vehicles
      </h2>
    </BackgroundImage>
    <BackgroundImage
      id="todo"
      tag="section"
      className="section"
      fluid={data.sandyBeach.childImageSharp.fluid}
    >
      <h1>... a two minute stroll away</h1>
      <h2>
        This magnificent sandy beach runs from your doorstep all the way into
        Southwold and beyond.
      </h2>
    </BackgroundImage>
    <BackgroundImage
      id="goldie"
      tag="section"
      className="section"
      fluid={data.hymerMain.childImageSharp.fluid}
    >
      <h1>
        Introducing 'The <span className="goldie">Goldie</span>'
      </h1>

      <h2>
        The Goldie glows with retro luxury, a very glamorous German motorhome,
        time travelling straight from the late 80s to wow you. She’s big too, at
        7.5m long.
      </h2>
    </BackgroundImage>
    <section id="goldie-carousel">
      <Carousel>
        <Carousel.Item>
          <BackgroundImage
            tag="section"
            className="section carousel-image"
            fluid={data.hymerLounge.childImageSharp.fluid}
          >
            <h2>
              The décor is soft and sensual, with warm tones of brown and cream,
              a touch of Boho chic mixed with ‘80s sophistication. Swivel in the
              leather captains chairs, taking in the panoramic views from the
              front lounge.
            </h2>
          </BackgroundImage>
        </Carousel.Item>
        <Carousel.Item>
          <BackgroundImage
            tag="section"
            className="section carousel-image"
            fluid={data.hymerKitchen.childImageSharp.fluid}
          >
            <h2>
              In the middle is the Bijou kitchen, everything you need from
              breakfast to dinner, a full selectionof crockery, cutlery and
              utensils all provided.
            </h2>
          </BackgroundImage>
        </Carousel.Item>
        <Carousel.Item>
          <BackgroundImage
            tag="section"
            className="section carousel-image"
            fluid={data.hymerBed.childImageSharp.fluid}
          >
            <h2>
              A boudoir bedroom at the back, with a sumptuous double bed and
              large window at the foot end with more glorious views all around.
            </h2>
          </BackgroundImage>
        </Carousel.Item>
        <Carousel.Item>
          <BackgroundImage
            tag="section"
            className="section carousel-image"
            fluid={data.hymerKitchenBedroom.childImageSharp.fluid}
          >
            <h2>
              The Goldie has its own little mirrored bathroom with a flushable
              toilet, sink and shower! There are lots of cupboards and even a
              wardrobe - enjoy dressing up and looking fabulous!
            </h2>
          </BackgroundImage>
        </Carousel.Item>
        <Carousel.Item>
          <BackgroundImage
            tag="section"
            className="section carousel-image"
            fluid={data.hymerInsideView.childImageSharp.fluid}
          >
            <h2>View out across fields and wildlife towards Southwold.</h2>
          </BackgroundImage>
        </Carousel.Item>
      </Carousel>
    </section>
    <BackgroundImage
      id="bonnie"
      tag="section"
      className="section"
      fluid={data.bonnieMain.childImageSharp.fluid}
    >
      <h1>
        Open air luxury, <span className="retro-shadow">The Bonnie</span>
      </h1>
      <h2>
        The Bonnie has a quirky charm. Stepping straight out of the early ‘80s,
        or the Space Odyssey film set, this Canadian camping creation has a cool
        sharp shape, and is most definitely one of a kind.
      </h2>
    </BackgroundImage>
    <section id="bonnie-carousel">
      <Carousel>
        <Carousel.Item>
          <BackgroundImage
            tag="section"
            className="section carousel-image"
            fluid={data.bonnieDining.childImageSharp.fluid}
          >
            <h2>
              The Bonnie’s unique and stunning secret… is it’s panoramic 360
              degree windows all with separate mesh and curtains.
            </h2>
          </BackgroundImage>
        </Carousel.Item>

        <Carousel.Item>
          <BackgroundImage
            tag="section"
            className="section carousel-image"
            fluid={data.bonnieKitchen.childImageSharp.fluid}
          >
            <h2>
              Two insanely comfy double beds either side of the kitchen, dining
              and lounging areas.
            </h2>
          </BackgroundImage>
        </Carousel.Item>
      </Carousel>
    </section>{" "}
    <BackgroundImage
      tag="section"
      id="roamers"
      fluid={data.sandyBeachHuts.childImageSharp.fluid}
    >
      <div className="shadow-fill">
        <h2>All part of the package...</h2>
        <ul>
          <li>
            Truly scrumptious natural double mattresses with cotton bedding. All
            the pillows and duvets are pure wool, made from sheep that run free
            on the Yorkshire hills.
          </li>
          <li>
            A kitchen with a sink, 2/4 hob cooker top, and fridge with a freezer
            compartment ….for ice to chill those sunset cocktails.
          </li>
          <li>
            Stylish high end kitchenware, including kettle, cafetiere, and
            saucepans to fit in with the glamorous retro feel.{" "}
          </li>
          <li>
            Sun loungers, outdoor table and chairs, parasol, wind breaks to
            create shelter and privacy. And fairy lights everywhere to magic up
            your stay.
          </li>
          <li>
            Fire pit and wood for a barbecue, or a cosy evening fire under the
            Southwold sky. Snuggle up in the shaggy rugs, as you lounge around
            the fire and look up …. stargazing here is startastic!
          </li>
          <li>Music Boom box</li>
          <li>
            Solar power, which provides USB charging points and internal
            lighting
          </li>
          <li>Water tanks.</li>
        </ul>
      </div>
    </BackgroundImage>
    <BackgroundImage
      id="pitch"
      tag="section"
      className="section"
      fluid={data.pitchMain.childImageSharp.fluid}
    >
      <h1>The Pitch</h1>
      <h2>
        Situated perfectly with a stunning sandy beach just a 2 minute walk from
        your pitch over the sand dunes. Walk out of the campsite- turn right and
        the Harbour begins, turn left and it’s a 10 minute stroll into Southwold
        town.
      </h2>
    </BackgroundImage>
    <BackgroundImage
      id="pitch2"
      tag="section"
      className="section"
      fluid={data.pitchFront.childImageSharp.fluid}
    >
      <h2>
        Your corner pitches are some of the most spacious and private on the
        site, awarding uninterrupted views of the surrounding nature reserve and
        the Southwold skyline including the iconic lighthouse, church and old
        water tower.
      </h2>
    </BackgroundImage>
    <BackgroundImage
      id="pitch3"
      tag="section"
      className="section"
      fluid={data.pitchField.childImageSharp.fluid}
    >
      <h2>
        In the day, the never-ending skyline is like a changing painting from
        sunrise to sunset, and part of the reason artists are often to be seen
        around painting. The campsite is unspoilt with no houses around with
        flocks of birds to watch and listen to from your sun loungers.
      </h2>
    </BackgroundImage>
    <BackgroundImage
      id="southwold1"
      className="section"
      tag="section"
      fluid={data.southwoldSkyline.childImageSharp.fluid}
    >
      <h1>Southwold</h1>
      <h2>
        Walk along the beach for a visit to the quirky pier of amusements. And
        then beyond, to the stunning open wild beaches of South Cove. Southwold
        has a vibrant traditional high street with lots of little shops to
        explore. There are also vintage markets and emporiums nearby.
      </h2>
    </BackgroundImage>
    <BackgroundImage
      id="southwold2"
      className="section"
      tag="section"
      fluid={data.southwoldPier.childImageSharp.fluid}
    >
      <h2>
        Situated perfectly with a stunning sandy beach just a 2 minute walk from
        your pitch over the sand dunes. Walk out of the campsite- turn right and
        the Harbour begins, turn left and it’s a 10 minute stroll into Southwold
        town.
      </h2>
    </BackgroundImage>
    <BackgroundImage
      id="southwold3"
      className="section"
      tag="section"
      fluid={data.southwoldHarbour.childImageSharp.fluid}
    >
      <h2>
        The Harbour is a delight of traditional atmosphere. Wander down past
        fishing boats and huts selling freshly caught fish. Here is the
        brilliant Mrs T’s: Fish and Chips. Next door is The Sole Bay Fish
        Company, specialising in seafood caught earlier that day from their own
        family boat. The Harbour Inn overlooks the tinkering bobbing boats where
        you can take in the sunset and relax.
      </h2>
    </BackgroundImage>
    <BackgroundImage
      id="southwold4"
      className="section"
      tag="section"
      fluid={data.sandyBeachHuts.childImageSharp.fluid}
    >
      <h2>
        It’s sandy beach is a dream for a splash or a swim, some bat and ball,
        soaking up the rays of sunshine or simply to watch the world go by.
      </h2>
    </BackgroundImage>
    <BackgroundImage
      tag="section"
      id="southwold-details"
      fluid={data.southwoldSkyline.childImageSharp.fluid}
    >
      <div className="shadow-fill">
        <h2>Southwold Highlights</h2>
        <p>
          Food and drink - there is a vast selection of fresh Southwold produce
          in characterful venues, all with their own unique charm. It offers
          candlelit restaurants, sea front beach hut cafes, pretty tea rooms
          with cottage gardens, scrumdiddily homemade take away food and luxury
          cuisine dining in The Swan and Crown hotel. To add to this are the
          ancient cosy pubs, history seeping from the worn slate floors, their
          walls adorned with vintage pics of Southwold’s days of old! There are
          food shops galore and fabulous fish and chip shops, to be munched on
          the harbour, pier or beach. For the ultimate outdoor eating … return
          to your Roamer with some freshly caught fish, to barbecue on your
          fairy lit pitch to some music, and finish the evening by going down to
          the beach to build a fire and listen to the waves!
        </p>
        <p>
          There are so many walks to choose from …a must is a stroll into the
          quaint little village of Walberswick, it really is a picture postcard
          village. Find the delightful tea room gardens, and two pubs who serve
          fantastic food. Then catch the rowing boat Ferry back. A cute end to
          an idyllic day.{" "}
        </p>
        <p>
          Cycle through the awesome Dunwich Heath and Forest to The Ship pub.
          After a well deserved meal wobble and giggle back through the grassy
          sea marshes on the boardwalks!{" "}
        </p>
        <p>
          Or perhaps you’d like to hire a boat at Oulton Broad and motor merrily
          merrily down the rivers with a bottle of champagne and picnic,
          stopping off for ice creams in sweet Suffolk villages.
        </p>
        <p>
          The area is famous for Adnams beer and it is made in Southwold itself.
          You can have a tour of the Brewery and make your own Adnams gin! Or
          ride around the town in a carriage pulled by the Adnams shire horses.
        </p>

        <p>
          As you walk back you will see a very impressive 9 hole golf course. Or
          there is a lovely Golf putting green by the sea front with tea rooms.
          For the even more energetic, you can find surf lessons and horse
          riding on the nearby beaches. Or for a peaceful morning, take a yoga
          class by the waves.
        </p>
        <p>
          A fantastical evening out where you must dress up is to wander up to
          the wonderfully eccentric Theatre cinema.{" "}
        </p>
        <p>
          This is just a small selection of the fun on offer…… if you are Retro
          Roaming for a much needed rest and don’t fancy exerting yourself then
          we have provided plenty of games to play on your pitch – from scrabble
          to connect 4, wooden puzzles to tease your brain for hours, Agatha
          Christie’s Who done it, Monopoly, the never ending challenging Rubix
          Cube! the Pro Swingball and the list goes on and on..….what with the
          views and the ambience of the Roamers, you could easily end up never
          ever leaving your pitch!
        </p>
      </div>
    </BackgroundImage>
    <BackgroundImage
      tag="section"
      id="booking"
      fluid={data.hymerSide.childImageSharp.fluid}
    >
      <div className="shadow-fill">
        <h2>Prices & Booking</h2>
        <p>
          Booking are directly with us by telephone or email, check below for
          dates and availability.
        </p>
        <p>
          Price is £100 a night, 4 night minimum and returnable deposit of £200
          when booking.
        </p>
      </div>
    </BackgroundImage>
  </Container>
)

export default WackyPage

export const query = graphql`
  query {
    bonairsAndDogs: file(relativePath: { eq: "bonairs-and-dogs.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    sandyBeach: file(relativePath: { eq: "sandy-beach.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    hymerMain: file(relativePath: { eq: "hymer-main.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    hymerLounge: file(relativePath: { eq: "hymer-lounge.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    hymerKitchen: file(relativePath: { eq: "hymer-kitchen.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    hymerBed: file(relativePath: { eq: "hymer-bed.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    hymerKitchenLounge: file(
      relativePath: { eq: "hymer-kitchen-lounge.jpeg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    hymerSide: file(relativePath: { eq: "hymer-side.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    hymerKitchenBedroom: file(
      relativePath: { eq: "hymer-kitchen-bedroom.jpeg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    hymerInsideView: file(relativePath: { eq: "hymer-inside-view.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bonnieMain: file(relativePath: { eq: "bonnie-main.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bonnieDining: file(relativePath: { eq: "bonnie-dining.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bonnieKitchen: file(relativePath: { eq: "bonnie-kitchen.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    pitchMain: file(relativePath: { eq: "pitch-main.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    pitchFront: file(relativePath: { eq: "pitch-front.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    pitchField: file(relativePath: { eq: "pitch-field.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    sandyBeachHuts: file(relativePath: { eq: "sandy-beach-huts.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    southwoldSkyline: file(relativePath: { eq: "southwold-skyline.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    southwoldPier: file(relativePath: { eq: "southwold-pier.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    southwoldHarbour: file(relativePath: { eq: "southwold-harbour.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
